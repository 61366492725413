import React from 'react';

const Timer = () => {
  const [countdown, setCountdown] = React.useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  React.useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const targetDate = new Date('Feb 25, 2025 21:00:00').getTime();
      const distance = targetDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
      <div>
        <p className='title'>ДО ЗАПУСКА ПРОЕКТА  ОСТАЛОСЬ</p>
      <div className="text">
        <p style={{ width: 'auto' }}>{countdown.days.toString().padStart(2, '0')}</p> <span>:</span>
        <p>{countdown.hours.toString().padStart(2, '0')}</p> <span>:</span>
        <p>{countdown.minutes.toString().padStart(2, '0')}</p> <span>:</span>
        <p>{countdown.seconds.toString().padStart(2, '0')}</p>
      </div>
      <p className='info'>Рождение проекта 25.02.2025</p>
    </div>
  );
};

export default Timer;
